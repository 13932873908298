@media screen and (min-width: 900px) {
    body {
        scrollbar-width: thin;
    }
    ::-webkit-scrollbar {
        width: 6px;
        //background-color: rgba(#000, .9);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #444;
        //background-image: linear-gradient($yellow, $yellow 50%, $blue 50%, $blue);
        /*background-image: -webkit-gradient(linear, 0 0, 0 100%,
                          color-stop(.5, rgba(255, 255, 255, .2)),
                                                  color-stop(.5, transparent), to(transparent));*/
    }
}

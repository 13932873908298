.entity-admin-menu {
	display: none;
    position: absolute;
    top: -12px;
    right: -12px;
}
div:hover {
    & > .entity-in > .entity-admin-menu,
    & > .entity-admin-menu {
        display: block;
    }
}
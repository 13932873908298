.field-manager {
    padding: 15px 0;
    display: flex;
    align-items: center;
    .field-type-select-form-control {
        min-width: 160px;
    }
    & > button {
        top: 12px;
    }
}

.field-admin-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    mix-blend-mode: difference;
}
.field-shadow {
    position: absolute;
    z-index: 1250;
    border: 2px solid;
    //outline: 1px dotted #ff0;
    border-image-source: 
        linear-gradient(45deg, 
        #EE617D 25%, #3D6F8E 25%, 
        #3D6F8E 50%, #EE617D 50%, 
        #EE617D 75%, #3D6F8E 75%, 
        #3D6F8E 100%);
    border-image-slice: 30;
    border-image-repeat: round;
    border-image-width: 2px;
    mix-blend-mode: difference;
    pointer-events: none;
    .field-admin-menu {
        //outline: 2px #ff0 solid;
        mix-blend-mode: difference;
        pointer-events: auto;
    }
}
.field_admin_hover {
    border: 1px #333 dotted;
}
button.field-admin-icon-button {
    background-color: rgba(#fff, .75);
    padding: 4px;
    &:hover {
        background-color: rgba(#fff, .95);
    }
}

.field-admin-menu {
    position: absolute;
    top: 4px;
    right: 4px;
    .under-header & {
        //top: var(--header-height);
        top: 100px;
    }
}
  
.upload-res-thumb {
	position: relative;
	display: inline-block;
	border: 2px #eee solid;
	margin: 1em;
	.remove {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	}
	&:hover {
	    .back, .responsive, .fwd,
		.remove {
			opacity: 1;
		}
	}
}
.upload-thumb {
	position: relative;
	width: 128px;
	height: 128px;
	display: inline-block;
	border: 2px #eee solid;
	margin: 1em;
	.upload-thumb-thumb {
		display: block;
		width: 128px;
		height: 128px;
		object-fit: cover;
		margin: 0;
	}
	.remove {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	}
	.back {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	    
	}
	.fwd {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	    
	}
	.responsive {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	    
	}
	.meta {
		display: block;
		position: absolute;
		top: 0;
		left: 32px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		opacity: 0;
		background: rgba(#fff, 0.1);
		color: rgba(#000, .4);
		cursor: pointer;
		
		&:hover {
			background: #fff;
			color: #000;
		}
	    
	}
	&.pdf {
		display: block;
		width: 100%;
		height: auto;
		padding: .3em 2.5em;
		margin:0;
		&:before {
			//content: $fa-var-file-pdf-o;
			//@include fa-icon;
			position: absolute;
			top: .5em;
			left: 1em;
		}
		&:hover {
			//background: $green;
			color: #fff;
		}
	}
	&.doc {
		display: block;
		width: 100%;
		height: auto;
		padding: .3em 2.5em;
		margin:0;
		&:before {
			//content: $fa-var-file-word-o;
			//@include fa-icon;
			position: absolute;
			top: .5em;
			left: 1em;
		}
		&:hover {
			//background: $green;
			color: #fff;
		}
	}
	&.xls {
		display: block;
		width: 100%;
		height: auto;
		padding: .3em 2.5em;
		margin:0;
		&:before {
			//content: $fa-var-file-excel-o;
			//@include fa-icon;
			position: absolute;
			top: .5em;
			left: 1em;
		}
		&:hover {
			//background: $green;
			color: #fff;
		}
	}
	
	&.other {
		display: block;
		width: 100%;
		height: auto;
		padding: .3em 2.5em;
		margin:0;
		&:before {
			//content: $fa-var-file-o;
			//@include fa-icon;
			position: absolute;
			top: .5em;
			left: 1em;
		}
		&:hover {
			//background: $green;
			color: #fff;
		}
	}
	
	&:hover {
	    .back, .responsive, .fwd, .meta,
		.remove {
			opacity: 1;
		}
	}
}
.dropzone {
	margin: 20px 0;
min-height: 128px;
    border: 1px dashed rgb(238, 238, 238);
    border-radius: 4px;
    padding: 15px 15px 75px;
	&.modifier {
		margin: 0;
	}
}
.dropzone.thumb {
	.upload-thumb {
		margin:0;
	}
}
.dropzone.header {
	//position: relative;
	//overflow: hidden;
	.upload-thumb {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin:0;
		z-index: 0;
		img {
			display: block;
			width: 100%;
			height: 100%;
			margin:0;
			object-fit: cover;
		}
	}
	.info {
		//position: relative;
		text-shadow: 1px 1px 0 #fff;
		.info-call {
			text-shadow: none;
		}
	}
}

.dropzone {
	cursor: pointer;
	//padding-bottom: 75px;
	position: relative;
	.info {
		position: absolute;
		bottom:0;
		left: 0;
		width: 100%;
		
		padding: 2em 0 1em;
		color: rgba(#000, .3);
		font-size: .8em;
		line-height: 1.3em;
		text-align: center;
		.info-call {
			display: inline-block;
			//background: $green;
			//color: rgba(#fff, .5);
			font-weight: bold;
			border-radius: 4px;
			padding: .3em 2em;
			&:hover {
				//background: darken($green, 10%);
				color: #fff;
			}
		}
	}
}
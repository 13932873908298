.menu-editor {
	.items {
		padding-left: 30px;
	}
	
	.menu-item {
		.menu-item-label {
			line-height: 40px;
			padding-right: 3em;
		}
		&>.menu-item-actions {
			display: none;
		}
		&:hover {
			&>.menu-item-actions {
				display: inline;
			}
		}
	}
}

html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }

body {
  margin: 0;
  padding: 0;
  font-family: $lato;
  font-size: 100%; //87.5%; //(14)
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  //font-size: 75%; // 12
  //-ms-text-size-adjust: 100%;
  //-webkit-text-size-adjust: 100%;
  line-height: 1.5em;
  color: $color-text;
  //letter-spacing: 1px;
}
@media screen and (min-width: 600px) {
    body {
        font-size: 87.5%; //(14)
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
@media screen and (min-width: 900px) {
    body {
        font-size: 100%; // 16
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
@media screen and (min-width: 1200px) {
    body {
        font-size: 106.3%; // 17
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5em;
    }
}
header, div, footer, h1, h3, p, section {
	box-sizing:border-box;
}
a {
	color: $color-link;
	text-decoration:none;
	&:hover {
		color: $color-link-hover;
	}
}
a.button {
	cursor: pointer;
}
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
img {
	width:auto;
	max-width:100%;
	max-height:100%;
	height:auto;
	margin:0;
	display:inline-block;
}
video {
    display: block;
    width: 100%;
}

h1 {
	font-size: 32px;
	line-height:1.6em;
	margin: 1em 0 0.5em;
}
h2 {
	font-size: 24px;
	line-height:1.6em;
	margin: 0 0 0.5em;
}
h3 {
	font-size: 1.2em;
	line-height:1.4em;
	margin: 0.5em 0;
}
div.p,
p {
	margin: 0 0 1em;
}

.lazy {
        overflow:hidden;
/*        filter: blur(5px);
        &.lazy-loaded {
        	//transition: filter .2s ease-in-out;
            filter: blur(0);
        }
        &.lazy-cache-loaded {
            filter: blur(0);
        }*/
}

@media screen and (min-device-width: 1024px) {
.lazy {
        overflow:hidden;
        /*
        filter: blur(5px);
        &.lazy-loaded {
        	transition: filter .2s ease-in-out;
            filter: blur(0);
        }
        &.lazy-cache-loaded {
            filter: blur(0);
        }
        */
}
}

.align-right {
	text-align: right;
}
.align-left {
	text-align: left;
}
.align-center {
	text-align: center;
}
.align-justify {
	text-align: justify;
}


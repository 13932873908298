.in-authenticating {
    opacity: 0;
}


.auth {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 800;
    .xss &,
    .xs &,
    .sm & {
        right: 0;
        bottom: 40px;
    }
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        //background: rgba($red, 0.6);
        //border: 1px rgba(#fff, .3) solid;
        color: $auth-icon-color;
        height: 24px;
        line-height: 24px;
        overflow: hidden;
        //border-radius: 32px;
        transition: all .3s;
        
        svg {
            width: 16px !important;
            height: 16px;
            margin: 4px;
        }
        span {
            display: inline-block;
            max-width: 0;
            transition: all .45s;
            font-size: 12px;
            line-height: 46px;
            padding: 0;
            overflow: hidden;
        }
        &:hover {
            //background: rgba($red, .8);
            span {
                max-width: 150px;
                padding: 0 16px 0 0;
            }
            color: $auth-icon-color-hover;
        }
    }
}
:root {
}
.xs {
}
.sm {
}
.md {
}
.lg,
.xl {
}

/*
xss: 0 - 400
xs:	400 - 600
sm: 600 - 900
md: 900 - 1200
lg: 1200 - 
*/
$auth-icon-color: rgba(#000, .6);
$auth-icon-color-hover: #000;

$color-text: #444;
$color-link: #222;
$color-link-hover: #000; 
$color-button: #000; 
$color-button-hover: #333; 
$color-scrollbar-thumb: rgba(#222, .5);
$color-scrollbar-track: rgba(#222, .1);
$menu-color: #333;
$menu-color-hover: #000;
$color-progress: #000 !default;
$bg-action: #333;

$roboto: Roboto, Helvetica, Arial, sans-serif;
$progress: #444;

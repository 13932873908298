[type-section].visible {
    & > h2[field-title] {
        opacity: 1;
        animation: bounceInRight 1s 1s both;
    }
    & > h3.section-shadow span {
        display: block;
        opacity: 1;
        animation: bounceInLeft 1s 1s both;
    }
}
[type-section] {
    position: relative;
    padding: 40px 0 0;
    background: #111;
    overflow: hidden;
    & > h2[field-title] {
        position: relative;
        z-index: 1;
        font-family: $lato;
        font-weight: 900;
        color: $yellow;
        font-size: 92px;
        line-height: 1em;
        margin: 1em 0 1em;
        text-align: center;
        text-transform: uppercase;
        opacity: 0;
        .xss &,
        .xs & {
            font-size: 48px;
            line-height: 1em;
        }
        .sm & {
            font-size: 64px;
            line-height: 1em;
            padding: 0 40px;
        }
    }
    & > h3.section-shadow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 0;
        font-family: $lato;
        font-weight: 900;
        //color: rgba($darkblue, .95);
        color: $darkblue;
        //font-size: 276px;
        font-size: 184px;
        line-height: 1em;
        margin: .5em 0;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        
        .xss &,
        .xs & {
            font-size: 96px;
            line-height: 1em;
        }
        .sm & {
            font-size: 144px;
            line-height: 1em;
        }
        span {
            opacity: 0;
        }
    }
    [section-content] {
        //padding: 40px 0;
        & > * {
            max-width: 960px;
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            &.wide {
                max-width: none;
            }
            .xss &,
            .xs & {
                padding-left: 20px;
                padding-right: 20px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            .sm & {
                padding-left: 40px;
                padding-right: 40px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            .md & {
                padding-left: 80px;
                padding-right: 80px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
    }
    &.content-grid {
        [section-content] {
            max-width: 960px;
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .xss &,
            .xs & {
                padding-left: 20px;
                padding-right: 20px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            .sm & {
                padding-left: 40px;
                padding-right: 40px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            .md & {
                padding-left: 80px;
                padding-right: 80px;
                &.wide {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            & > a {
                flex: 20% 1 0;
                //margin-bottom: 50px;
                padding: 20px;
                font-weight: 900;
                text-align: center;
                background: #222;
                margin: 2px;
                &:hover {
                    background: $darkblue;
                }
                .xss &,
                .xs & {
                    flex: 20% 1 0;
                }
            }
            
        }
    }
}
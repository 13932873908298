#style-dialog-content {
    display: flex;
    .style-index {
        height: 70vh;
        flex: 0 0 250px;
        overflow-y: auto;
    }
    .style-content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 250px);
        .style-editor {
            padding-top: 5px;
            height:100%;
        }
    }
    .tab-label {
        text-align: left;
        & > button {
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
}
.dialog-fullscreen {
    .dialog-content {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    #style-dialog-content {
        flex-grow: 1;
        
    }
}

.in-authenticating {
    opacity: 0;
}
.header-admin-menu {
    top: 20px;
    right: 80px;
    .xss &,
    .xs & {
        top: 15px;
        right: 50px;
    }
    .lg &, .xl & {
        right: 100px;
    }
    button svg {
        transition: all .4s ease-in-out;
        color: rgba($darkyellow, .6);
        .not-top & {
            color: rgba(#fff, .7);
        }
    }
    button:hover svg {
        color: $darkyellow;
        .not-top & {
            color: #fff;
        }
    }
}

.auth {
    position: absolute;
    bottom: 20px;
    right: 86px;
    z-index: 800;
    .xss &,
    .xs &,
    .sm & {
        right: 20px;
        bottom: 40px;
    }
    div {
        display: flex;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        background: rgba($darkyellow, 0.6);
        border: 1px rgba(#fff, .3) solid;
        color: rgba(#fff, .6);
        height: 46px;
        line-height: 46px;
        overflow: hidden;
        border-radius: 32px;
        transition: all .3s ease-in-out;
        
        svg {
            width: 24px !important;
            height: 24px;
            margin: 11px;
        }
        span {
            display: inline-block;
            max-width: 0;
            transition: all .45s;
            font-size: 12px;
            line-height: 46px;
            padding: 0;
            overflow: hidden;
        }
        &:hover {
            background: $yellow;
            span {
                max-width: 150px;
                padding: 0 16px 0 0;
            }
            color: #111;
        }
    }
}
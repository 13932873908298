.field-manager {
    padding: 20px 80px;
    color: #fff;
    label {
        color: #fff;
    }
    .MuiInput-underline:before  {
        border-color: #fff;
    }
    svg {
        fill: #fff;
    }
}
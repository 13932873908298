#content-view {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#fff, .95);
    z-index: 1000;
    
    #content-view-sidebar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 320px;
        border-right: 1px #eee solid;
        padding: 20px;
        .cv-menu-item {
            padding: .5em 1em;
            border-bottom: 1px #eee solid;
            cursor: pointer;
            &:hover {
                background: #eee;
            }
        }
    }
    #content-view-content {
        position: absolute;
        left: 320px;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 20px;
        
        .cv-rows {
            position: absolute;
            left: 0;
            top: 64px;
            bottom: 20px;
            right: 0;
            overflow: hidden;
        }
       .cv-row {
            position: relative;
            padding: 1em 2em;
            border-bottom: 1px #eee solid;
            .ops {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
            }
            &:hover {
                background: #eee;
                .ops {
                    opacity: 1;
                }
            }
        }
    }
}
#mobile-menu-button {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: #fff;
    margin-right: 40px;
    .xss &,
    .xs & {
        margin-right: 10px;
    }
    .sm &,
    .md & {
        margin-right: 20px;
    }
    svg {
        width: 48px;
        fill: rgba($darkyellow, .6);
        .not-top & {
            fill: rgba(#fff, .7)
        }
        transition: all .3s ease-in-out;
        .xss &, .xs &, .sm & {
            width: 32px;
        }
    }
    &:hover {
        svg {fill: $darkyellow;}
        .not-top & {
            svg {fill: #fff;}
        }
    }

}
#menu-close {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 15px;
    
    svg {
        width: 32px;
        fill: rgba(#fff, .7);
        transition: all .3s ease-in-out;
        .xss &, .xs &, .sm & {
            width: 32px;
        }
    }
    &:hover {
        svg {fill: #fff;}
    }
    
}
.menu-wrap {
    position: absolute;
    top: 0;
    right: 0px;
    display: block;
    padding: 20px;
    background: #111;
    transform: translate3d(0, -100%, 0);
    transition: all .4s ease-in-out;
    .sm &,
    .md &, .lg &, .xl & {
        padding: 40px;
    }
    &.menu-open {
        transform: translate3d(0, 0, 0);
    }
    .xss &,
    .xs & {
        right: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sm &,
    .md & {
        right: 0;
    }
}
.main-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 10px;
    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
        display: block;
    }
    a {
        padding: .5em 1em;
        text-transform: uppercase;
        font-weight: 900;
        .xss &,
        .xs & {
            display: block;
            margin: 5px 0;
            width: 60vw;
        }
        .sm &,
        .md &,
        .lg &,
        .xl & {
            display: block;
            margin: 1px 0;
        }
    }
}

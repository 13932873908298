/*
xss: 0 - 400
xs:	400 - 600
sm: 600 - 900
md: 900 - 1200
lg: 1200 - 
*/
$lato: 'Lato', sans-serif;
$oswald: 'Oswald', sans-serif;

$yellow: #fadd3a;
$darkyellow: #b8a958;
//$darkblue: #101e27;
$darkblue: #0e202a;


$lightblue: #2081c2;
$red: #d53e30;
$green: #33532e;
$light-green: #acc043;
$gray: #484848;
$light-black: #555;

$color-text: #222;
$color-link: $light-black;
$color-link-hover: #fff;
$color-button: $green;
$color-button-hover: lighten($green, 10%);
$color-scrollbar-thumb: rgba(#222, .5);
$color-scrollbar-track: rgba(#222, .1);
$menu-color: #fff;
$menu-color-hover: $light-green;
$color-progress: $red;

.white {
	color: #fff;
}
.yellow {
	color: $yellow;
}
.light-green,
.green {
	color: $light-green;
}

[type-html] {
    opacity: 0;
    &.visible {
        animation: fadeIn 1s 1s both;
    }
}
.cols2 {
    column-count: 2;
    column-gap: 180px;
    background: url(/img/alumetasistem-A-nobg.svg) center center no-repeat;
    background-size: 64px;
    text-align: justify;
    .xss &,
    .xs & {
        column-count: 1;
        text-align: left;
        background: none;
    }
}
.flex {
    display: flex;
    align-items: flex-start;
    & > * {
        display: block;
    }
}
.twopic {
    img {
        opacity: 0;
    }
    &.visible {
        img:first-child {
        opacity: 1;
            animation: zoomIn 1s both;
        }
        img:last-child {
        opacity: 1;
            animation: zoomIn 1s .5s both;
        }
    }
}
[type-img] {
    img {
        width: 100%;
    }
}
[type-img].img-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr) ) ;
    .xss &,
    .xs & {
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr) ) ;
    }
    .sm &,
    .md & {
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr) ) ;
    }
}

[type-img].img-grid {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    //align-items: flex-start;
    img {
        //flex: 30% 0 0;
        display: block;
        //margin-bottom: 20px;
        opacity: 0;
        //transform: scale(0);
        .xss &,
        .xs & {
            flex: 47% 0 0;
        }
    }
    &.visible img {
        opacity: 1;
        animation: zoomIn .5s both;
        &:nth-child(1) {animation-delay: 0.2s;}
        &:nth-child(2) {animation-delay: 0.3s;}
        &:nth-child(3) {animation-delay: 0.4s;}
        &:nth-child(4) {animation-delay: 0.5s;}
        &:nth-child(5) {animation-delay: 0.6s;}
        &:nth-child(6) {animation-delay: 0.7s;}
        &:nth-child(7) {animation-delay: 0.8s;}
        &:nth-child(8) {animation-delay: 0.9s;}
        &:nth-child(9) {animation-delay: 1.0s;}
        &:nth-child(10) {animation-delay: 1.1s;}
        
    }
}
.img-hala {
    margin: 40px 0 0;
    padding: 0;
    img {
        display: block;
        width: 100%;
        margin: 0;
        opacity: 0;
    }
    &.visible img {
        animation: fadeIn 1s 1s both;
    }
}
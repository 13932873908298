#form-dialog-title,
.form-dialog-title {
    background: #444;
    cursor: grab;
    &.grabbing {
        cursor: grabbing;
    }
    h6 {
        color: #fff;
    }
}
.dialog-fullscreen {
    .form-dialog-title {
        cursor: default;
    }
}
.lg, .xl {
    .dialog-root .dialog-paper.dialog-window {
        width: 80vw;
        max-width: 1024px;
    }
}
.dialog-paper {
  background: #fff;
}

.header-admin-menu {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    .xss &, .xs &, .sm & {
        right: 64px;
    }
}
#form-dialog-title,
.form-dialog-title {
    background: #444;
    cursor: grab;
    &.grabbing {
        cursor: grabbing;
    }
    h6 {
        color: #fff;
    }
}
.dialog-fullscreen {
    .form-dialog-title {
        cursor: default;
    }
}
/*
.lg, .xl {
    .dialog-root .dialog-paper.dialog-window {
        width: 80vw;
        max-width: 800px;
    }
}*/
.field-admin {
    .field-admin-menu {
        display: none;
    }

    &:hover {
        position: relative;
        & > .field-admin-border {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px dotted #bbb;
            z-index: 1000;
            //margin: -1px;
        }
        & > .field-admin-menu {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10000;
        }
        & > [block-content] {
            position: relative;
        }
    }
}

.list-editor {
    .le-list-item {
        display: flex;
        border: 1px dotted #ddd;
        .le-list-item-index {
            flex-grow: 0;
            color: #ddd;
            font-size: 32px;
            padding: 12px;
        }
        .le-list-item-content {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-items: center;
            padding: 5px;
        }
        .le-list-item-grow {
            flex-grow: 0;
        }
    }
    .le-list-item-actions {
        display: flex;
        align-items: center;
        justify-items: center;
    }
}
.color-picker {
    width: 170px;
    overflow: hidden;
    .selected-color {
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            font-size: 18px;
            color: #000;
        }
    }
    .palette {
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(5, 22px);
        grid-gap: 10px;
        div {
            width: 22px;
            height: 22px;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .editor {
        padding: 10px;
    }
}
.wysiwyg-wrapper {
    position: relative;
}
.wysiwyg-toolbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
    border: 1px #eee solid;
    &.focus {
        //display: block;
    }
    
} 
    .img-upload {
        padding: 20px;
        .dropzone {
            width: 160px;
            height: 160px;
            border: 2px #eee dashed;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            color: #ddd;
            cursor: pointer;
            margin: 0 auto;
            div {
                display: inline-block;
            }
        }
        .buttonbar {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
        }
    }
.wysiwyg-editor {
    position: relative;
    //max-height: 300px;
    border: 1px #eee solid;
    border-top: none;
    padding: 0 10px;
    overflow-y: auto;
    .DraftEditor-root {
        position: relative;
    }
    .public-DraftEditorPlaceholder-root {
        position: absolute;
        color: #ddd;
        z-index: 0;
    }
    .DraftEditor-editorContainer {
        position: relative;
        z-index: 1;
    }
}
.field-admin {
    .field-admin-menu {
        display: none;
    }
    
    &:hover {
        position: relative;
        & > .field-admin-border {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px dotted #bbb;
            z-index: 1000;
            //margin: -1px;
        }
        & > .field-admin-menu {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10000;
        }
        & > [block-content] {
            position: relative;
        }
    }
}

.list-editor {
    .le-list-item {
        display: flex;
        border: 1px dotted #ddd;
        .le-list-item-index {
            flex-grow: 0;
            color: #ddd;
            font-size: 32px;
            padding: 12px;
        }
        .le-list-item-content {
            flex-grow: 1;
        }
        .le-list-item-grow {
            flex-grow: 0;
        }
    }
}

#form-dialog-title {
    background: #444;
    h2 {
        color: #fff;
    }
}
.form-language-selector {
    background: #666;
    padding: 8px 12px;
}
@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading {
    position: relative;
    width: 100%;
    height: 100%;
    svg {
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
            transform-origin: center center;
            animation: fa-spin 2s infinite linear;
        }
    }
}

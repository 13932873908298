html {
  scroll-behavior: smooth;
}
body {
  font-family: $lato;
  font-size: 22px;
  line-height: 1.5em;
  font-weight: 300;
  &.xss,
  &.xs {
    font-size: 18px;
    line-height: 1.5em;
      font-weight: 300;
  }
}
body[data-website="loaded"] {
  background: #111;
  color: rgba(#fff, .8);
}
strong {
    font-weight: 900;
}
em {
    //font-weight: 400;
}
a {
    color: $color-link;
    transition: all .4s ease-in-out;
    &:hover {
        color: $color-link-hover;
    }
}
/*
h1 {
	font-size: var(--h1);
	line-height:1.6em;
	margin: .5em 0 0.5em;
}
*/

.vertpad {
	padding-top: 40px;
	padding-bottom: 40px;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  //transform: translate3d(0, 0, 0);

  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
    transition: transform .45s, opacity .45s;
  }


  &.active:after,
  &:active:after,
  &:hover:after {
    transform: scale(10);
    opacity: .3;
    //transition: 0s;
  }
}
@mixin ripple-right {
  position: relative;
  overflow: hidden;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $rose 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
    transition: transform .45s, opacity .45s;
  }

  &.active:after,
  &.selected:after,
  &:active:after,
  &:hover:after {
    transform: scale(10);
    opacity: .3;
    //transition: 0s;
  }
}

@mixin icon-text-button {
    display: flex;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    background: rgba($color-button, .5);
    color: rgba(#fff, .8);
    height: 46px;
    line-height: 46px;
    overflow: hidden;
    border-radius: 32px;
    transition: all .45s;
        
    i {
        font-size: 24px;
        width: 46px;
        text-align: center;
        line-height: 45px;
        padding-bottom: 1px;
    }
    span {
        display: inline-block;
        max-width: 0;
        transition: all .45s;
        font-size: 12px;
        line-height: 46px;
        padding: 0;
        overflow: hidden;
    }
    &:hover {
        background: rgba($color-button, .98);
        color: #fff;
        span {
            max-width: 150px;
            padding: 0 16px 0 0;
        }
    }
}    
.icon-text-button {
    @include icon-text-button;
}

@mixin round-button {
    display: inline-block;
    box-sizing: border-box;
    font-size: var(--round-size);
    line-height: 1em;
    font-weight: 600;
    padding: var(--round-padding);
    margin: 5px;
    border-radius: var(--round-border);
    border: 1px #e9e9e9 solid;
    transition: all .45s ease-in-out;
    &:hover { 
        background: $yellow;
        border-color: $yellow;
        color: #555;
    }
}

.round-button {
    @include round-button;
}
.round-button.active {
    @include round-button;
    background: $yellow;
    border-color: $yellow;
    color: #555;
}

        ul {
            li {
                list-style-type: square;
            }
        }

#svg200 {
    .st0{opacity:0.1;}
	.st1{fill:#FFFFFF;}
	.st2{opacity:0.5;}
	.st3{fill:none;}
	.st4{opacity:0.2;fill:#FFFFFF;}
	.st5{opacity:0.3;}
}
#sb-txt-2 {
    .st0{fill:#FFFFFF;}
	.st1{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
	.st2{opacity:0.5;}
	.st3{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
	.st4{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}
}
#svgSbTxt1 {
    .st0{opacity:0.3;fill:#FFFFFF;}
	.st1{fill:#FFFFFF;}
	.st2{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
	.st3{opacity:0.3;}
	.st4{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
	.st5{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
	.st6{opacity:0.7;fill:#FFFFFF;}
	.st7{opacity:0.15;fill:#FFFFFF;enable-background:new    ;}
	.st8{opacity:0.5;}
	.st9{opacity:0.1;fill:#FFFFFF;enable-background:new    ;}
	.st10{opacity:0.2;fill:#FFFFFF;}
	.st11{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}

}
#SbText1 {
    .st0{fill:#FFFFFF;}
    .st1{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
    .st2{opacity:0.5;}
    .st3{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
    .st4{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}
    .st5{opacity:0.5;fill:#FFFFFF;}
    .st6{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
    .st7{opacity:0.05;fill:#FFFFFF;enable-background:new    ;}
    .st8{opacity:0.6;}
    .st9{opacity:0.4;fill:#FFFFFF;enable-background:new    ;}
    .st10{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
}
#SbText2 {
    .st0{fill:#FFFFFF;}
    .st1{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
    .st2{opacity:0.5;}
    .st3{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
    .st4{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}
}
#LogoAllWhiteNew {
    .st0{fill:#FFFFFF;}
    .st1{opacity:0.25;fill:#FFFFFF;enable-background:new    ;}
}
.icon-logo-dotari {
    svg {
        width: 130px;
        margin-bottom: 20px;
    }
}
#SbText3 {
    .st0{fill:#FFFFFF;}
    .st1{opacity:0.1;}
    .st2{opacity:0.2;fill:#FFFFFF;}
}
#SbPiese1 {
    .st0{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
    .st1{fill:#FFFFFF;}
    .st2{opacity:0.7;fill:#FFFFFF;enable-background:new    ;}
    .st3{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
}
#SbPiese2 {
    .st0{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
    .st1{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
    .st2{fill:#FFFFFF;}
    .st3{opacity:0.7;}
    .st4{opacity:0.8;fill:#FFFFFF;enable-background:new    ;}
}
#SbPiese3 {
    .st0{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}
    .st1{opacity:0.5;}
    .st2{fill:#FFFFFF;}
    .st3{opacity:0.1;fill:#FFFFFF;enable-background:new    ;}
    .st4{opacity:0.2;fill:#FFFFFF;enable-background:new    ;}
}
body {
	/*overflow: hidden;
	//position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left:0;*/
}

scrollbar,
[scrollbar],
[data-scrollbar] {
	position: relative;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.scroll-content {
		transform:translateZ(0)
	}

	.scrollbar-track{
		position:absolute;
		opacity:0;
		z-index:1000;
		transition:opacity .5s ease-out,background .5s ease-out;
		background:none;
		user-select:none;
	}

	&.sticky .scrollbar-track{
		background:hsla(0,0%,87%,.75)
	}	

	.scrollbar-track.show,
	.scrollbar-track:hover{
		opacity:1;
	}
	.scrollbar-track:hover{
		//background: hsla(0,0%,87%,.75);
		background: $color-scrollbar-track;
	}
	.overscroll-glow{
		position:absolute;top:0;left:0;width:100%;height:100%
	}
	.scrollbar-track-y{
		top:0;
		right:0;
		width:8px;
		height:100%;
	}
	.scrollbar-track-x{
		display: none;
	}
	.scrollbar-thumb{
		position:absolute;top:0;left:0;
		width:8px;height:8px;
		//background:rgba(0,0,0,.5);
		border-radius:4px;
		background: $color-scrollbar-thumb;
	}
}



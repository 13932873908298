[data-website="loading"] {
    #header {
        transform: translate3d(0, -100%, 0);
    }
}
#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: all .4s ease-in-out;
    padding: 10px;
    display: flex;
    .not-top & {
        background: rgba(#222, .95);
        box-shadow: 2px 2px 10px rgba(#000, .8);
    }
    .header-in {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
}
[type-form] input[type=text], [type-form] textarea {
    background: #111;
    border-top-color: #111;
    border-left-color: #111;
    border-right-color: #111;
}
#section-f4 [section-content] {
    max-width: 960px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    .xss &,
    .xs &,
    .sm {
        display: block;
    }
    & > [field-f1] {
        margin: 0;
        padding: 20px 40px 20px 0;
        .visible {
            opacity: 1;
            animation: fadeIn 1s both;
        }
        &:before {
            content: "";
            display: block;
            border-top: 15px $yellow solid;
            width: 30%;
            margin-bottom: 40px;
        }
        h2 {
            color: rgba(#fff, 1);
            font-size: 32px;
            line-height: 1em;
            font-weight: 900;
        }
        p {
            color: rgba(#fff, .4);
            //font-size: .9em;
            font-size: 16px;
            a {
                color: rgba(#fff, .8);
                &:hover {
                    color: $yellow;
                }
            }
        }
        .xss &,
        .xs &,
        .sm {
            padding-left: 20px;
        }
    }
    & > [field-f0] {
        flex: auto 1 0;
        margin: 0;
        padding-right: 0;
    }
}
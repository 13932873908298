:root {
	--max-width: 1200px;
	--side-padding: 15px;
	--node-padding: 0 15px 20px;
    --hero-padding: 180px 80px 120px;
    --title-font-size: 74px;
}
.xs {
	--side-padding: 20px;
	--node-padding: 0 30px 20px;
}
.sm {
	--side-padding: 30px;
	--node-padding: 0 40px 20px;
}
.md {
	--side-padding: 40px;
	--node-padding: 0 40px 0px;
}
.lg,
.xl {
	--side-padding: 80px;
	--node-padding: 0 80px 0px;
    --hero-padding: 180px 80px 120px;
    --title-font-size: 74px;
}

.introbg {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100vh;
    background: #111;
    overflow: hidden;
    opacity: 0;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        min-width: 100%;
        min-height: 100vh;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        background: rgba(#ececec, .9);
    }
}
@media (min-aspect-ratio: 16/9) {
  .introbg video {
    height: 400%;
    top: -150%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .introbg video {
    width: 400%;
    left: -150%;
  }
}

[type-intro] {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 10;
        //background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .intro-logo {
            position: relative;
            flex: 75vw 0 0;
            opacity: 0;
            max-width: 35vw;
            max-height: 75vh;
            .portrait & {
                flex: 90vw 0 0;
                max-width: 90vw;
            }
            .bg-rect {
                //fill: yellow;
            }
        }
        
        /*.intro-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: url(/img/1-lg-l.jpg) center center no-repeat;
            background-size: cover;
            opacity: 0;
        }
        .intro-bg-over {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: #ececec;
            opacity: 0;
        }*/
        .intro-slogan {
            position: absolute;
            top: 45vh;
            left: 100vw;
            width: 100vw;
            font-family: $lato;
            font-size: 10vh;
            line-height: 1em;
            text-transform: uppercase;
            //color: #fadd3a;
            color: #111;
            font-weight: 900;
            text-shadow: 1px 1px 0 #fadd3a;
            .portrait & {
                width: 200vw;
            }
            span {
                //color: #fadd3a;
                //text-shadow: none;
            }
        }
        .intro-title {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $lato;
            font-size: 20vh;
            line-height: .85em;
            text-transform: uppercase;
            font-weight: 900;
            text-align: center;
            color: #111;
            text-shadow: 1.5vw 1.2vh 0 #fadd3a;
            overflow: hidden;
            .portrait & {
                font-size: 20vw;
                line-height: .85em;
            }
            div {
                transform: translate3d(0, 100vh, 0);
            }
        }
}
[type-form] {
    button {
        display: inline-block;
        background: $yellow;
        color: #222;
        font-size: 20px;
        line-height: 1em;
        margin-top: 15px;
        padding: 0.5em 1.5em;
        border: 0;
        border-radius: 4px;
        cursor: pointer;
        transition: all .4s ease;
        &:hover {
            background: lighten($yellow, 10%);
            color: #000;
        }
    }
}



.formular {
	input,textarea {
        display: block;
        transform: translateX(50px);
        opacity:0;
        transition: all .5s ease-out .5s;
    }
    
    .status {
    	text-align: center;
    	font-size: 32px;
    	line-height: 1.4em;
    	
    	&.sending {
    		//color: $yellow;
    		.fa {
    			//color: $yellow;
    			display: block;
    			margin: 0 auto;
    		}
    	}
    	&.sent {
    		//color: $yellow;
    		.fa {
    			display: block;
    			margin: 0 auto;
    		}
    	}
    }
}
.visible {
		input,textarea {
			opacity: 1;
			transform: translateX(0);
		}	
}

[type-form] {
    padding: 20px;
    input[type=text], textarea {
        //background: rgba($lightblue, .2);
        background: #222;
        color: #fff;
        border: 0;
        border: 1px solid #525252;
        //border-bottom: 1px rgba($gray, .2) solid;
        outline: none;
        padding: .5em;
        width: 100%;
        display: block;
        box-sizing: border-box;
        margin-bottom: 15px;
        font-family: inherit;
        font-size: inherit;
        font-size: .875em;
        &::placeholder {
        	//color: rgba($gray, .3);
        }
    }
    
    textarea {
    	//border: 1px rgba($gray, .2) solid;
    	height: 160px;
        //background: rgba(#000, .5);
        //color: #fff;
    }
    /*input[type=text], textarea {
        border: 1px rgba(#000, .1) solid;
        border-radius: 2px;
    }
    a.button {
        margin-top: 15px;
    }*/
}

.content-view {
    padding-top: 24px;
    .content-view-index {
        display: flex;
        & > * {

            &:first-child {
                flex-grow: 0;
            }
            &:last-child {
                flex-grow: 1;
                padding-left: 15px;
            }
        }
    }
    .content-view-list {
        max-height: 60vh;
        overflow-y: auto;
    }
}

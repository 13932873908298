

[type-html] {
    & > .field-inner {opacity: 0;}
    &.field-visible > .field-inner {
        opacity: 1;
        animation: fadeInUpSmall 1s .5s both;
    }
}
[type-block] {
    & > [block-inner] > .bg {
        opacity: 0;
    }
    &.field-visible {
        & > [block-inner] > .bg {
            opacity: 1;
            animation: fadeIn 1s .5s both;
        }
    }
}

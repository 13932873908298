#logo-svg {
    width: 200px;
    margin: 10px 0 0 40px;
    .st0, .st1 {transition: all .4s ease-in-out;}
    .st0{fill:#222222;}
    .st1{fill:$darkyellow;}
    .not-top & {
        .st0{fill:#FADD3A;}
        .st1{fill:#FFFFFF;}
    }
    .xss &,
    .xs & {
        width: 150px;
        margin: 10px 0 0 10px;
    }
    .sm & {
        width: 180px;
        margin: 10px 0 0 10px;
    }
    .md & {
        margin: 10px 0 0 20px;
    }
}